import SplunkOtelWeb, { SessionBasedSampler } from '@splunk/otel-web';
import getSplunkConfigData from './api/HPCGateway';
import { SPLUNK_RUM } from './utils/constants';

const splunkRUMInitialize = () => {
  getSplunkConfigData().then(data => {
    SplunkOtelWeb.init({
      beaconUrl: SPLUNK_RUM.beaconUrl,
      rumAuth: data.hpsmart_rum_token,
      app: SPLUNK_RUM.app_name,
      environment: data.splunk_env,
      tracer: {
        sampler: new SessionBasedSampler({
          ratio: 0.5,
        }),
      },
    });
  }).catch(err => {
    console.error(err);
    return true;
  });
};

export default splunkRUMInitialize;
